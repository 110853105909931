@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
  --toastify-icon-color-error: #ff3d3c !important;
  --toastify-color-progress-error: #ff3d3c !important;
  --toastify-icon-color-success: #17e383 !important;
  --toastify-color-progress-success: #17e383 !important;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #eb6c4c !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #e48b75 !important;
}

/* Custom Navbar Style */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e5e5e;
}

/* Hide number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@keyframes indicator {
  0%,
  100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-indicate {
  animation: indicator 1s infinite;
}
